import styled from '@emotion/styled';
import { topRoundedCorners } from './RoundedCorners';

const CategoryHeading = styled.h2`
  && {
    margin: 0;
    text-transform: uppercase;
    font-size: var(--size-body1);
    color: var(--black-high);
    font-family: var(--font-secondary);
    display: block;
    ${topRoundedCorners};

    ${(properties) => properties.jobOfTheWeek && `
      && {
        color: var(--white);
        background-color: ${
  properties.theme.palette?.primaryFallback.main
};
        background-color: ${
  properties.theme.palette?.primary.main
};
        display: block;
        text-align: center;
        padding: 4px 12px;
      }
    `}
  }
`;

export default CategoryHeading;
